document.addEventListener("DOMContentLoaded", function() {
  var cross = document.querySelectorAll(".faq__cross");

  for (var i = 0; i < cross.length; i++) {
    cross[i].addEventListener("click", function() {
      this.classList.toggle("faq__cross--close");
      this.nextElementSibling.children[0].classList.toggle(
        "faq__question__title--open"
      );
      this.nextElementSibling.nextElementSibling.classList.toggle(
        "faq__answer--open"
      );
    });
  }
});
