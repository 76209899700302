<template>
  <div class="category-search">
    <div class="category-search__container">
      <!-- TABS SWITCH: Between Portfolio and Bussines owner -->
      <div class="category-search__tabs" ref="tabs">
        <span class="category-search__tabs-title">Filter</span> 
        <button @click ="dropdownIsOpen =! dropdownIsOpen" class="category-search__dropdown">
          <span>
            {{tabs.filter(t => t.section === section)[0].name}}
            <svg :class="{'open' : dropdownIsOpen}" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M12 5.99841C11.9927 5.78391 11.9159 5.53598 11.788 5.37962L7.61413 0.279409C7.3313 -0.0128817 6.78895 -0.133543 6.47283 0.204408C6.16145 0.537259 6.17072 1.18279 6.48091 1.51697L9.4157 5.0984L0.782608 5.0984C0.3504 5.0984 -4.31221e-07 5.50133 -4.71182e-07 5.99841C-5.11144e-07 6.49548 0.350399 6.89844 0.782607 6.89844L9.4157 6.89844L6.48091 10.4799C6.21386 10.7875 6.16451 11.4558 6.47283 11.7924C6.78107 12.1291 7.34291 12.0215 7.61413 11.7174L11.788 6.61719C11.9309 6.44265 12.0005 6.23787 12 5.99841Z" fill="#fff"/>
            </svg>
          </span>
        </button> 
        <!-- START Mobile tab switch -->
        <div class="category-search__dropdown-buttons" :class="{'open' : dropdownIsOpen}">
          <a
            :class="[
              'category-search__dropdown-button',
              { active: section === tab.section }
            ]"
            @click="changeSection(tab.section); dropdownIsOpen = false"
            v-for="tab in tabs"
            :key="`mobile_${tab.section}`"
            :href="'#' + tab.section"
          >
            {{tab.name}}
          </a>
        </div>
        <!-- END Mobile tab switch -->

        <!-- START Desktop tab switch -->
        <div
          :class="[
            'category-search__tabs-single',
            { active: section === tab.section }
          ]"
          @click="changeSection(tab.section)"
          v-for="tab in tabs"
          :key="tab.section"
        >
          <a :href="'#' + tab.section" class="category-search__link">{{ tab.name }}</a>
        </div>
        <!-- END Desktop tab switch -->
      </div>
      <!-- END TABS SWITCH -->

      <!-- FILTER CATEGORY between Portfolio and Bussines owner -->
      <div class="category-search__filters" v-if="!hideFilters.selected">
        <strong>{{ lang == "en" ? "Sort by" : "Sorteren" }}</strong>
        <select v-model="mainCategory" v-if="categories" @change="changeMainCategory">
          <option :value="null">
            {{
            lang == "en" ? "Everything" : "Alle categorieën"
            }}
          </option>

          <option v-for="option in categories" :value="option" :key="option.id">{{ option.title }}</option>
        </select>
        <select
          v-model="subCategory"
          @change="triggerSearch"
          :disabled="!mainCategory || !mainCategory.subcategories.length"
        >
          <option :value="null">
            {{
            lang == "en" ? "Alle categorieën" : "Everything"
            }}
          </option>
          <template v-if="mainCategory && mainCategory.subcategories.length">
            <option
              v-for="subCat in mainCategory.subcategories"
              :value="subCat"
              :key="subCat.id"
            >{{ subCat.title }}</option>
          </template>
        </select>
      </div>
      <!-- END FILTER CATEGORY -->

      <transition-group name="fade" mode="out-in">
        <!-- Start Ondernemers -->
        <div
          v-if="section === 'ondernemers' && results.length && !loading"
          key="ondernemers"
          :class="['category-search__result', 'flex-column']"
        >
          <a
            v-for="result in results.slice(0, offset)"
            :href="`${lang == 'en' ? '/en/' : '/'}${result.uri}`"
            :key="result.id"
            class="category-search__entry category-search__entry--client"
          >
            <div class="category-search__entry-meta">
              <strong
                v-if="result.category"
                class="category-search__category"
              >{{ result.category[0].title }}</strong>
              <h3 class="category-search__title" v-html="result.title">{{ result.title }}</h3>
              <p
                v-html="result.field_clientSubtitle"
                class="entry-description"
              >{{ result.field_clientSubtitle }}</p>
            </div>
            <div class="category-search__entry-img">
              <div
                class="entry-img-wrapper"
                v-if="result.backgroundImage"
                :style="{
                  backgroundImage: `url(${result.backgroundImage})`
                }"
              ></div>
              <div v-else class="entry-no-image">
                <strong>{{ result.title }}</strong>
              </div>
            </div>
          </a>
        </div>
        <!-- End Ondernemers -->

        <!-- Start ondernemers-in-beeld -->
        <div
          v-if="section === 'ondernemers-in-beeld'"
          key="ondernemers-in-beeld"
          :class="['category-search__result', 'flex-column']"
        >
        <!-- Slot passed in Filter.twig, no API PHP calls needed -->
          <slot></slot>
        </div>
        <!-- End ondernemers-in-beeld -->

        <!-- Start Portefeuille -->
        <div
          v-if="section === 'portefeuille' && results.length && !loading"
          key="portefeuille"
          :class="['category-search__result', 'flex-row']"
        >
          <!-- FILTER PAST&CURRENT  -->
          <div class="category-search__pastcurrent">
            <div class="category-search__pastcurrent__wrapper">
              <button @click="showCategory" class="title category-search__pastcurrent__button">
                <span
                  v-if="portfolioStateName === 'all'"
                >{{ lang == "en" ? "Sorted by Everything" : "Gesorteerd op Alles" }}</span>
                <span
                  v-if="portfolioStateName === 'currentPortfolio'"
                >{{ lang == "en" ? "Sorted by Current portfolio" : "Gesorteerd op Huidige portefeuille" }}</span>
                <span
                  v-if="portfolioStateName === 'previousInvestments'"
                >{{ lang == "en" ? "Sorted by Previous investments" : "Gesorteerd op Voormalige investeringen" }}</span>
                <svg
                  class="category-search__pastcurrent__arrow"
                  width="12"
                  height="12"
                  viewBox="0 0 12 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12 5.99841C11.9927 5.78391 11.9159 5.53598 11.788 5.37962L7.61413 0.279409C7.3313 -0.0128817 6.78895 -0.133543 6.47283 0.204408C6.16145 0.537259 6.17072 1.18279 6.48091 1.51697L9.4157 5.0984L0.782608 5.0984C0.3504 5.0984 -4.31221e-07 5.50133 -4.71182e-07 5.99841C-5.11144e-07 6.49548 0.350399 6.89844 0.782607 6.89844L9.4157 6.89844L6.48091 10.4799C6.21386 10.7875 6.16451 11.4558 6.47283 11.7924C6.78107 12.1291 7.34291 12.0215 7.61413 11.7174L11.788 6.61719C11.9309 6.44265 12.0005 6.23787 12 5.99841Z"
                  />
                </svg>
              </button>
              <div class="category-search__pastcurrent__menu">
                <div class="category-search__pastcurrent__point"></div>
                <ul class="category-search__pastcurrent__list">
                  <li @click="filterResults()" class="category-search__pastcurrent__listitem title">
                    <span>{{ lang == "en" ? "Everything" : "Alles" }}</span>
                    <span>({{ countPortfolioState() }})</span>
                  </li>
                  <li
                    @click="filterResults('currentPortfolio')"
                    class="category-search__pastcurrent__listitem title"
                  >
                    <a href="#portefeuille">
                      <span>{{ lang == "en" ? "Current portfolio" : "Huidige portefeuille" }}</span>
                      <span>({{ countPortfolioState("currentPortfolio") }})</span>
                    </a>
                  </li>
                  <li
                    @click="filterResults('previousInvestments')"
                    class="category-search__pastcurrent__listitem title"
                  >
                    <a href="#portefeuille">
                      <span>{{ lang == "en" ? "Previous investments" : "Voormalige investeringen" }}</span>
                      <span>({{ countPortfolioState("previousInvestments") }})</span>
                    </a>
                  </li>
                </ul>
              </div>
              <div @click="showCategory()" class="category-search__pastcurrent__layer"></div>
            </div>
          </div>
          <!-- END FILTER PAST&CURRENT -->
          <a
            v-for="result in results.slice(0, offset)"
            :href="`${lang == 'en' ? '/en/' : '/'}${result.uri}`"
            :key="result.id"
            class="category-search__entry category-search__entry--portefeuille"
          >
            <div class="category-search__entry-meta">
              <img :src="result.normal_thumbnail" class="category-search__port-image" />
              <h3 class="category-search__title">{{ result.title }}</h3>
              <div class="entry-description type-slide">
                <img :src="result.hover_thumbnail" class="category-search__port-image" />
                <span class="entry-description__text">{{ result.field_portefeuilleSubtitle }}</span>
                <span class="entry-description-readmore">
                  <h3 class="entry-description-readmore-text">{{ result.title }}</h3>
                </span>
              </div>
            </div>
          </a>
        </div>
        <!-- End Portefeuille -->
        
        <!-- Start Load more -->
        <div
          key="loadmore"
          class="category-search__loadmore"
          v-if="results.length > offset && !loading && section !== 'ondernemers-in-beeld'"
          @click="loadMore"
        >{{ lang == "en" ? "Load more" : "Meer laden" }}</div>
        <!-- End Load more -->
        <!-- Start No results -->
        <div v-if="!results.length && !loading && section !== 'ondernemers-in-beeld'" key="no-result" class="category-search__no-result">
          {{
          lang == "en"
          ? "The query did not return any matches"
          : "Er konden geen resultaten worden gevonden"
          }}
        </div>
        <!-- End No results -->
        <!-- Start Loading -->
        <div
          v-if="loading && section !== 'ondernemers-in-beeld'"
          key="no-result"
          class="category-search__no-result"
        >{{ lang == "en" ? "Loading results" : "Resultaten worden geladen" }}</div>
        <!-- End Loading -->
      </transition-group>

      <!-- bottom buttons for other categories -->
      <div class="work">
        <category-links :title="lang == 'en'  ? 'We\'re also happy to introduce our partners'  : 'Maak verder kennis met onze partners'" @changeSection="changeSection" :tabs="tabs.filter((t) => t.section !== section)" v-if="!loading" :lang="lang"></category-links>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { setTimeout } from "timers";
export default {
  name: "CategorySearch",
  props: {
    tabs: { type: Array, required: true },
    lang: { type: String, default: "en" },
    hideFilters: { type: Object, default: () => ({}) }
  },
  data() {
    return {
      dropdownIsOpen: false,
      portfolioStateName: "all",
      categories: null,
      mainCategory: null,
      subCategory: null,
      completeResults: [],
      results: [],
      section: "ondernemers",
      loading: true,
      offset: 10
    };
  },
  computed: {
    counterSection() {
      return this.tabs.find(t => t.section !== this.section);
    }
  },
  methods: {
    filterResults(event) {
      if (event) {
        let temp = this.completeResults.filter(function(a) {
          return a.portfolioState.value == event;
        });
        this.results = temp;
        this.portfolioStateName = event;
      } else {
        this.results = this.completeResults;
        this.portfolioStateName = "all";
      }
      this.showCategory();
    },
    countPortfolioState(event) {
      if (event) {
        let portfolioVal = this.completeResults.filter(function(a) {
          return a.portfolioState.value == event;
        });
        return portfolioVal.length;
      } else {
        return this.completeResults.length;
      }
    },
    showCategory() {
      let menu = document.querySelector(".category-search__pastcurrent__menu");
      let layer = document.querySelector(
        ".category-search__pastcurrent__layer"
      );
      menu.classList.toggle("show");
      layer.classList.toggle("active");
    },
    changeMainCategory() {
      this.subCategory = null;
      this.triggerSearch();
    },
    async triggerSearch() {
      
      if (this.section === "ondernemers-in-beeld"){ return }

      this.loading = true;
      let resp = null;


      if (!this.mainCategory) {
        resp = await axios.get(`api/entries/${this.section}`);
        this.completeResults = resp.data.data;
        this.results = this.completeResults;
      } else {
        const id = this.subCategory
          ? this.subCategory.id
          : this.mainCategory.id;
        resp = await axios.get(`api/entries/${this.section}/${id}`);
        this.completeResults = resp.data.data[0].entries;
        this.results = this.completeResults;
      }
      this.loading = false;
    },
    changeSection(section, onload = false) {
      
      let newSection = this.tabs.filter(t => t.section === section)[0];
      document.querySelector(".main_header").style.backgroundImage = `url("${newSection.image}")`;
      document.querySelector(".main_header__title").innerHTML = newSection.title;

      // "ondernemers-in-beeld" doesn't need to be loaded as it's already in the slot hiddin behind a v-if
      if (section === "ondernemers-in-beeld"){ this.loading = false }

      if (section === this.section && !onload) {
        return;
      }
      this.section = section;
      this.mainCategory = this.subCategory = null;
      this.offset = this.section === "ondernemers" ? 10 : 9;
      this.triggerSearch();
      
      window.location.hash = this.section;
    },
    loadMore() {
      this.offset += this.section === "ondernemers" ? 10 : 9;
    }
  },
  async mounted() {
    const resp = await axios.get("/api/categories");
    this.categories = resp.data.data.filter(c => c.isMain);
    if (window.location.hash) {
      this.changeSection(window.location.hash.replace("#", ""), true);
      setTimeout(() => {
        window.scrollTo(
          0,
          document.querySelector(".category-search__tabs").offsetTop - 150
        );
      });
      return;
    }
    await this.triggerSearch();
  }
};
</script>

<style lang="scss">
.fade-enter-active {
  transition: 300ms all ease-in-out;
}

.fade-enter-active {
  transition-delay: 0s;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}
// styles located in category-filter.scss
</style>
