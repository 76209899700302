document.addEventListener('DOMContentLoaded', function () {
  let openMenu = document.querySelector('.hamburger'),
    menuItems = true,
    mainHeader = document.querySelectorAll('.main_header'),
    mainHeaderWrap = document.querySelector('.main_header__wrap'),
    nav = document.querySelector('.main_header__nav'),
    subMenu = document.getElementById('nav-submenu'),
    normalLinks = document.querySelectorAll(
      ".main_header__nav__origin .main-menu__link:not(.main-menu__link[href^='#'])",
    ),
    categoryLinks = document.querySelectorAll(".main-menu__link[href^='#']"),
    mainHeaderLinkY = 20,
    scrollToButton = document.querySelector('.goto'),
    scrollTo = document.querySelector('#anchor')

  if (mainHeader) {
    openMenu.addEventListener('click', function () {
      nav.classList.toggle('open')
      document.body.classList.toggle('no-scroll')
      mainHeaderWrap.classList.toggle('open')
      this.classList.toggle('is-active')
      menuItems = !menuItems
    })
  }

  // removes submenu on hover
  normalLinks.forEach((link) => {
    link.addEventListener('mouseenter', (e) => {
      hideSubmenu()
      for (let i = 0; i < categoryLinks.length; i++) {
        categoryLinks[i].classList.remove('active')
        categoryLinks[i].classList.add('soft')
      }
      for (let i = 0; i < normalLinks.length; i++) {
        normalLinks[i].classList.add('soft')
      }
    })
    link.addEventListener('mouseleave', (e) => {
      for (let i = 0; i < normalLinks.length; i++) {
        normalLinks[i].classList.remove('soft')
      }
      for (let i = 0; i < categoryLinks.length; i++) {
        categoryLinks[i].classList.remove('soft')
      }
    })
  })

  categoryLinks.forEach((link) => {
    // prevens to url to change on click on href=#
    link.addEventListener('click', (e) => {
      e.preventDefault()
    })
    // activates/switches submenu on hover
    link.addEventListener('mouseenter', (e) => {
      let idString = decodeURI(e.target.href.split('#')[1])
      swapSubmenu(idString)
      for (let i = 0; i < categoryLinks.length; i++) {
        categoryLinks[i].classList.remove('active')
        categoryLinks[i].classList.add('soft')
      }
      for (let i = 0; i < normalLinks.length; i++) {
        normalLinks[i].classList.add('soft')
      }
      link.classList.add('active')
    })
  })
  window.addEventListener('scroll', () => {
    // 140 is for nav wrap height
    if (window.scrollY > scrollToButton.offsetTop + scrollToButton.offsetHeight - 140) {
      scrollToButton.classList.remove("active");
    }
  })

  window.addEventListener('resize', () => {
    for (let i = 0; i < categoryLinks.length; i++) {
      categoryLinks[i].classList.remove('active')
    }
    hideSubmenu();
  })

  function swapSubmenu(id) {
    hideSubmenu()
    window.setTimeout(() => {
      document.getElementById(id).scrollIntoView()
      showSubmenu()
    }, 200)
  }
  function hideSubmenu() {
    subMenu.classList.add('hidden')
  }
  function showSubmenu() {
    subMenu.classList.remove('hidden')
  }

  if (scrollToButton) {
    scrollToButton.addEventListener('click', function () {
      scrollTo.scrollIntoView({ behavior: 'smooth', block: 'start' })
    })
  }

  const videoBtn = document.querySelector('#home_video_btn')
  const video = document.querySelector('#home_video')
  if (videoBtn) {
    videoBtn.addEventListener('click', (e) => {
      e.preventDefault()
      let mouseTimer = null
      document.querySelector('#home_video_preview').remove()
      const videoContainer = document.querySelector(
        '.main_header__video-container',
      )

      const headerEl = document.querySelector('.main_header')
      headerEl.classList.toggle('video-playing')

      videoContainer.classList.add('play-state')
      video.play()
      videoContainer.addEventListener('click', () => {
        video.paused ? video.play() : video.pause()
      })
      videoContainer.addEventListener('mousemove', () => {
        clearTimeout(mouseTimer)
        headerEl.classList.add('pause-hover')
        mouseTimer = setTimeout(() => {
          headerEl.classList.remove('pause-hover')
        }, 1000)
      })
      const simulateFullscreen = () => {
        window.scrollTo(0, 0)
        document.querySelector('body').classList.add('no-scroll')
        headerEl.classList.remove('paused')
      }
      simulateFullscreen()
      video.addEventListener('playing', () => {
        simulateFullscreen()
      })
      video.addEventListener('pause', () => {
        document.querySelector('body').classList.remove('no-scroll')
        headerEl.classList.add('paused')
      })
      video.play()
    })
  }

  let headerViewHeight = window.innerHeight
  let headerWidth = window.innerWidth

  if (headerWidth < 768) {
    document.querySelector('.main_header').style.height =
      headerViewHeight + 'px'
  }
})
