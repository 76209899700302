document.addEventListener('DOMContentLoaded', function () {
  let button = document.getElementById('disclaimer-btn')

  if (button) {
    document.body.scrollTo(0, 0)
    document.body.style.overflow = 'hidden'
    button.addEventListener('click', function (e) {
      let checked = true
      e.target.parentElement
        .querySelectorAll(".disclaimer__checkboxes input[type='checkbox']")
        .forEach((checkbox) => {
          if (!checkbox.checked) {
            checked = false
            checkbox.parentElement.classList.add('checkbox-wrong')
          } else {
            checkbox.parentElement.classList.remove('checkbox-wrong')
          }
        })
      if (checked) {
        document.body.style.overflow = null
        let disclaimerEl = document.getElementById('disclaimer')
        disclaimerEl.classList.add("disclaimer--gone")
        setTimeout(function () {
          disclaimerEl.parentNode.removeChild(disclaimerEl)
        }, 1000)
      }
    })
  }
})
