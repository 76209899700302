document.addEventListener("DOMContentLoaded", function() {
	let usp = document.querySelector(".usp"),
		uspHover = document.querySelectorAll(".usp__wrap");

	if (usp) {
		for (let i = 0; i < uspHover.length; i++) {
			uspHover[i].addEventListener("mouseover", function() {
				this.classList.add("uspHover");
			});
			uspHover[i].addEventListener("mouseout", function() {
				this.classList.remove("uspHover");
			});
		}
	}
});
