<template>
  <div class="video-placeholder">
    <div class="video-placeholder__image">
      <slot></slot>
      <video autoplay controls v-if="open">
        <source :src="url" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <button class="video-placeholder__open-btn" @click="open = true">
        <svg
          width="124"
          height="124"
          viewBox="0 0 124 124"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M80.8395 57.7736C83.5714 60.1641 83.5714 64.4141 80.8395 66.8045L59.951 85.0819C56.0715 88.4765 50 85.7214 50 80.5665L50 44.0117C50 38.8567 56.0715 36.1017 59.951 39.4962L80.8395 57.7736Z"
            fill="white"
          />
          <circle cx="62" cy="62" r="60" stroke="white" stroke-width="4" />
        </svg>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "VideoPlaceholder",
  props: {
    url: { type: [String], required: true },
    closeTxt: { type: [String], required: true },
  },
  data() {
    return {
      open: false,
    };
  },
};
</script>