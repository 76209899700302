<template>
  <div>
    <div class="video-modal" v-if="open">
      <div class="video-modal__wrap wrap">
        <div class="video-modal__container">
          <video ref="video" autoplay controls>
            <source :src="videos[index].url" type="video/mp4" />
            Your browser does not support the video tag.
          </video>
          <div class="video-modal__navigation">
            <button @click="setVid(index - 1)">
              <svg
                width="18"
                height="16"
                viewBox="0 0 18 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M17 7C17.5523 7 18 7.44772 18 8C18 8.55228 17.5523 9 17 9V7ZM0.292892 8.70711C-0.0976315 8.31658 -0.0976315 7.68342 0.292892 7.29289L6.65685 0.928932C7.04738 0.538408 7.68054 0.538408 8.07107 0.928932C8.46159 1.31946 8.46159 1.95262 8.07107 2.34315L2.41421 8L8.07107 13.6569C8.46159 14.0474 8.46159 14.6805 8.07107 15.0711C7.68054 15.4616 7.04738 15.4616 6.65685 15.0711L0.292892 8.70711ZM17 9H1V7H17V9Z"
                  fill="#2D3856"
                />
              </svg>
              <span>{{
                videos[index - 1]
                  ? videos[index - 1].title
                  : videos[videos.length - 1].title
              }}</span>
            </button>
            <button @click="setVid(index + 1)">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="16"
                viewBox="0 0 18 16"
                fill="none"
              >
                <path
                  d="M1 7C0.447715 7 0 7.44772 0 8C0 8.55228 0.447715 9 1 9V7ZM17.7071 8.70711C18.0976 8.31658 18.0976 7.68342 17.7071 7.29289L11.3431 0.928932C10.9526 0.538408 10.3195 0.538408 9.92893 0.928932C9.53841 1.31946 9.53841 1.95262 9.92893 2.34315L15.5858 8L9.92893 13.6569C9.53841 14.0474 9.53841 14.6805 9.92893 15.0711C10.3195 15.4616 10.9526 15.4616 11.3431 15.0711L17.7071 8.70711ZM1 9H17V7H1V9Z"
                  fill="#2D3856"
                />
              </svg>
              <span>{{
                videos[index + 1] ? videos[index + 1].title : videos[0].title
              }}</span>
            </button>
          </div>
        </div>
        <button class="video-modal__close-btn" @click="open = false">
          {{ closeTxt }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Value and Videos Componenent",
  props: {
    data: { type: [Array], required: true },
    closeTxt: { type: [String], required: true },
  },
  data() {
    return {
      videos: [],
      index: 0,
      open: false,
    };
  },
  mounted() {
    this.videos = JSON.parse(this.data);

    // adds event listener to the buttons in the "valuesVideos.twig" file
    for (let i = 0; i < this.videos.length; i++) {
      let el = document.getElementById(this.videos[i].title);
      el.addEventListener("click", () => {
        this.index = parseInt(el.getAttribute("index"));
        this.open = true;
      });
    }
  },
  methods: {
    setVid(newIndex) {
      console.log(newIndex);
      if (newIndex == this.videos.length){
        this.index = 0;
      } else if (newIndex == -1){
        this.index = this.videos.length-1
      } else {
        this.index = newIndex;
      }
      this.$refs.video.load();
    }
  }
};
</script>