import "@babel/polyfill";

import Vue from "vue";
import "./components";
import "./sass/global.scss";
import "./js/header";
import "./js/faq";
import "./js/scroll";
import "./js/location";
import "./js/usp";
import "./js/hero";
import "./js/introAnime";
import "./js/work";
import "./js/lightboxvideo";
import "./js/filternews";
import "./js/disclaimer";
import "./js/values";


import lozad from "lozad";


Vue.config.productionTip = false;

new Vue({
	el: "#app",
	delimiters: ["${", "}"]
});

const observer = lozad(); // lazy loads elements with default selector as ".lozad"
observer.observe();
