import { CountUp } from "countup.js";

const initParallax = function(query) {
  const elems = [];
  for (const el of Array.from(document.querySelectorAll(query))) {
    if (!el) {
      continue;
    }
    el.style.opacity = 0;
    elems.push(el);
    //TODO: set correct on pageload (NTH)
  }
  const animationTrigger = () => {
    for (const el of elems) {
      el.style.opacity = 1;
      const vpo = el.getBoundingClientRect();
      // stop animating when out of screen boundaries
      if (vpo.top * -1 < window.innerHeight) {
        el.style.transform = `translateY(${vpo.top / -5}px)`;
      }
    }
  };
  setTimeout(() => {
    animationTrigger(); // trigger to correct
  }, 300);
  window.addEventListener("scroll", animationTrigger);
};
export const controller = new ScrollMagic.Controller({});

document.addEventListener("DOMContentLoaded", function() {
  // init all parallax elements
  initParallax(".frontdoor__image-inner");
  initParallax(".frontdoor2__image-inner");

  for (const fdOne of document.querySelectorAll(".frontdoorAnime")) {
    new ScrollMagic.Scene({
      offset: -300,
      triggerElement: "#" + fdOne.id
    })
      .setClassToggle("#" + fdOne.id, "show")
      .reverse(false)
      .addTo(controller);
  }

  for (const fdOne of document.querySelectorAll(".contactdoor")) {
    new ScrollMagic.Scene({
      offset: -300,
      triggerElement: "#" + fdOne.id
    })
      .setClassToggle("#" + fdOne.id, "show")
      .reverse(false)
      .addTo(controller);
  }

  new ScrollMagic.Scene({
    triggerElement: ".uspAnime",
    offset: -300
  })
    .setClassToggle(".uspAnime", "show")
    .reverse(false)
    .addTo(controller);

  for (const hd of document.querySelectorAll(".hoverdoor__article")) {
    new ScrollMagic.Scene({
      offset: -300,
      triggerElement: "#" + hd.id
    })
      .setClassToggle("#" + hd.id, "show")
      .reverse(false)
      .addTo(controller);
  }
  //
  for (const triggerElement of document.querySelectorAll(
    "[data-scroll-effect]"
  )) {
    triggerElement.classList.add(triggerElement.dataset.scrollEffect);
    triggerElement.removeAttribute("data-scroll-effect");
    const offset = triggerElement.getBoundingClientRect().height * -0.5;
    new ScrollMagic.Scene({ triggerElement, offset })
      .setClassToggle(triggerElement, "show")
      .reverse(false)
      .addTo(controller);
  }

  /* Sample function that returns boolean in case the browser is Internet Explorer*/
  /* Leaving this function incase client changes his mind. Again... */
  function isIE() {
    var ua = navigator.userAgent;
    /* MSIE used to detect old browsers and Trident used to newer ones*/
    var is_ie = ua.indexOf("MSIE ") > -1 || ua.indexOf("Trident/") > -1;

    return is_ie;
  }

  const numberScene = new ScrollMagic.Scene({
    triggerElement: ".statistics__numbers",
    offset: -300
  })
    .setClassToggle(".statistics__numbers", "show")
    .reverse(false)
    .addTo(controller);

  if (window.innerWidth > 1280) {
    numberScene.on("enter", e => {
      for (const nr of document.querySelectorAll(
        ".statistics__numbers .statistics__number"
      )) {
        const match = nr.textContent.match(
          /^(?<prefix>[^0-9]*)(?<number>[0-9]+)(?<suffix>.*)$/
        );

        if (!match) {
          console.warn(`${nr.textContent} is not a number`);
          return;
        }
        // console.log(match);

        const obj = {
          int: parseInt(match.groups.number, 10)
        };

        const options = {
          prefix: match.groups.prefix,
          suffix: match.groups.suffix
        };

        const demo = new CountUp(nr, obj.int, options);
        if (!demo.error) {
          demo.start();
        } else {
          console.error(demo.error);
        }
      }
    });
  }

  let intViewportWidth = window.innerWidth;

  if (intViewportWidth < 768) {
    for (const uspWrap of document.querySelectorAll(".usp__wrap")) {
      new ScrollMagic.Scene({
        duration: uspWrap.offsetHeight,
        triggerElement: "#" + uspWrap.id
      })
        .setClassToggle("#" + uspWrap.id, "uspHover")
        .reverse(true)
        // .addIndicators()
        .addTo(controller);
    }
  }

  for (const member of document.querySelectorAll(".team__member")) {
    const offset = member.getBoundingClientRect().height * -0.5;
    new ScrollMagic.Scene({
      ...(!!member.dataset.isMobile && { duration: member.offsetHeight + 300 }),
      triggerElement: "#" + member.id,
      offset: offset
    })
      .setClassToggle("#" + member.id, "show")
      .reverse(!!member.dataset.isMobile)
      .addTo(controller);
  }
  if (intViewportWidth < 768) {
    for (const workUsp of document.querySelectorAll(".work__grid__block")) {
      new ScrollMagic.Scene({
        duration: workUsp.offsetHeight,
        triggerElement: "#" + workUsp.id
      })
        .setClassToggle("#" + workUsp.id, "workHover")
        .reverse(true)
        // .addIndicators()
        .addTo(controller);
    }
  }

  var nav = document.querySelector(".main_header__wrap");
  window.addEventListener("scroll", function() {
    if (this.scrollY > 50) {
      nav.classList.add("fix");
    } else {
      nav.classList.remove("fix");
    }
    if (
      this.innerHeight + this.pageYOffset + 200 >=
      document.body.offsetHeight
    ) {
      document.querySelector(".footer__wrap").classList.add("show");
    }
  });
});
