document.addEventListener("DOMContentLoaded", function() {
	let introAnime = document.querySelector(".introAnime"),
		introAnimeTimeLine = new TimelineLite();

	if (introAnime) {
		introAnimeTimeLine
			.to(".introAnime__slide001", 1, {
				delay: 0.5,
				left: 0,
				ease: Power4.easeInOut
			})
			.to(".introAnime__slide002", 1, {
				left: 0,
				ease: Power4.easeInOut
			})
			.to(".introAnime", 0.5, {
				delay: -0.3,
				right: "-100%"
			});
	}
});
