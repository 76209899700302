let active = null;
document.addEventListener("DOMContentLoaded", function() {
  document.querySelectorAll("[data-location-hover]").forEach(el => {
    const location = document.querySelector(
      ".location__profile-image[data-location-image='" +
        el.dataset.locationHover +
        "']"
    );

    el.addEventListener("mouseenter", () => {
      if (active) {
        active.classList.remove("show");
      }
      if (location) {
        active = location;
        active.classList.add("show");
      }
    });
  });
});
