<template>
  <div class="work__button calltoaction">
    <div class="work__more-title"> 
      {{title}}
    </div>
    <div class="link-cards">
      <div
        class="link-cards__card"
        v-for="tab in tabs"
        :key="`tab_${tab.section}`"
      >
        <div class="link-cards__background">
          <img :src="tab.image" :alt="`image-${tab.section}`" />
        </div>
        <a
          :href="'#' + tab.section"
          @click.prevent="changeSection(tab.section)"
          class="link-cards__link"
          :class="{ more: onTeamsPage }"
        >
          <div class="background"></div>
          <div class="link-cards__link-text">{{ tab.name }}</div>
          <div class="link-cards__link-arrow">
            {{ lang == "en" ? "Watch now" : "Bekijk nu" }}
            <svg
              class="link-cards__svg-arrow"
              width="12"
              height="12"
              viewBox="0 0 12 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12 5.99841C11.9927 5.78391 11.9159 5.53598 11.788 5.37962L7.61413 0.279409C7.3313 -0.0128817 6.78895 -0.133543 6.47283 0.204408C6.16145 0.537259 6.17072 1.18279 6.48091 1.51697L9.4157 5.0984L0.782608 5.0984C0.3504 5.0984 -4.31221e-07 5.50133 -4.71182e-07 5.99841C-5.11144e-07 6.49548 0.350399 6.89844 0.782607 6.89844L9.4157 6.89844L6.48091 10.4799C6.21386 10.7875 6.16451 11.4558 6.47283 11.7924C6.78107 12.1291 7.34291 12.0215 7.61413 11.7174L11.788 6.61719C11.9309 6.44265 12.0005 6.23787 12 5.99841Z"
                fill="#fff"
              />
            </svg>
          </div>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: { type: String, required: true },
    tabs: { type: Array, required: true },
    lang: { type: String, default: "en" },
    onTeamsPage: { type: Boolean, default: false },
  },
  methods: {
    changeSection(str) {
      if (this.onTeamsPage) {
        location.href = this.lang === "en" ? `our-investments#${str}` : `onze-investeringen#${str}`;
      } else {
        this.$emit("changeSection", str);
      }
    },
  },
};
</script>
