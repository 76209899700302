document.addEventListener("DOMContentLoaded", function() {
	let work = document.querySelectorAll(".work__toggle");

	if (work) {
		for (let woo = 0; woo < work.length; woo++) {
			work[woo].addEventListener("click", function() {
				this.parentNode.classList.toggle("workHover");
				this.classList.toggle("rotate");
			});
		}
	}
});
