document.addEventListener('DOMContentLoaded', function () {
  let values = document.querySelectorAll('.values__box--has-hover')

  if (values.length) {
    // Reference: http://www.html5rocks.com/en/tutorials/speed/animations/
    let lastKnownScrollPosition = 0
    let ticking = false

    function doSomething(scrollPos) {
      var bodyRect = document.body.getBoundingClientRect();
      for (let i = 0; i < values.length; i++) {
        let elemRect = values[i].getBoundingClientRect(),
          offset = elemRect.top - bodyRect.top + elemRect.height / 2,
          center = offset - scrollPos - window.innerHeight / 2
        if (center < 150 && center > -150) {
          values[i].classList.add('in-focus')
        } else {
          values[i].classList.remove('in-focus')
        }
      }
    }

    document.addEventListener('scroll', function (e) {
      lastKnownScrollPosition = window.scrollY

      if (!ticking) {
        window.requestAnimationFrame(function () {
          doSomething(lastKnownScrollPosition)
          ticking = false
        })

        ticking = true
      }
    })
  }
})
