document.addEventListener("DOMContentLoaded", function() {
	const lightboxvideo = document.querySelector(".lightboxvideo"),
		lightboxvideoPlayButton = document.querySelectorAll(
			".lightboxvideo__video-toggle--play"
		),
		lightboxvideoClose = document.querySelectorAll(".lightboxvideo__close"),
		lightboxvideoOpenVideo = document.querySelectorAll(
			".lightboxvideo__openvideo"
		);

	if (lightboxvideo) {
		for (let i = 0; i < lightboxvideoOpenVideo.length; i++) {
			lightboxvideoOpenVideo[i].addEventListener("click", function() {
				this.nextElementSibling.classList.add("show");
			});
		}
		for (let x = 0; x < lightboxvideoClose.length; x++) {
			lightboxvideoClose[x].addEventListener("click", function() {
				this.parentNode.parentNode.classList.remove("show");
				this.nextElementSibling.pause();
				this.nextElementSibling.nextElementSibling.toggle("videostate");
			});
		}

		for (let y = 0; y < lightboxvideoPlayButton.length; y++) {
			lightboxvideoPlayButton[y].addEventListener("click", function() {
				this.previousElementSibling.paused
					? this.previousElementSibling.play()
					: this.previousElementSibling.pause();
				this.classList.toggle("videostate");
			});
		}
	}
});
