<template>
  <div class="faq">
    <div
      :class="['faq__wrap', { 'faq__wrap--open': activeItems.includes(key) }]"
      v-for="(item, key) in items"
      :key="key"
      @click="toggleItem(key)"
    >
      <div class="faq__cross">
        <svg
          width="25"
          height="25"
          viewBox="0 0 25 25"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M12.5 0C11.5478 0 10.7759 0.771935 10.7759 1.72414V10.7758H1.72414C0.77194 10.7758 0 11.5477 0 12.5C0 13.4522 0.77194 14.2241 1.72414 14.2241H10.7759V23.2759C10.7759 24.2281 11.5478 25 12.5 25C13.4522 25 14.2241 24.2281 14.2241 23.2759V14.2241H23.2759C24.2281 14.2241 25 13.4522 25 12.5C25 11.5477 24.2281 10.7758 23.2759 10.7758H14.2241V1.72414C14.2241 0.77194 13.4522 0 12.5 0Z"
          />
        </svg>
      </div>
      <div class="faq__question">
        <p class="faq__question__title title" v-html="item.question">
          {{ item.question }}
        </p>
      </div>
      <div
        :class="[
          'faq__answer',
          { 'faq__answer--open': activeItems.includes(key) }
        ]"
      >
        <div class="answer-wrapper" v-html="item.answer">
          {{ item.answer }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FaqComponent",
  props: {
    items: { type: [Array], required: true }
  },
  data() {
    return {
      activeItems: []
    };
  },
  methods: {
    toggleItem(key) {
      if (this.activeItems.includes(key)) {
        this.activeItems = this.activeItems.filter(k => k !== key);
        return;
      }
      this.activeItems.push(key);
    }
  }
};
</script>

<style lang="scss">
.scale-enter-active {
  transition: 500ms all ease-in-out;
  max-height: 500px;
  padding: 30px 60px;
}

.scale-enter-active {
  transition-delay: 0s;
}

.scale-enter,
.scale-leave-active {
  max-height: 0;
  padding: 0;
}
</style>
