document.addEventListener("DOMContentLoaded", function() {
	let hero = document.querySelector(".hero"),
		heroButton = document.querySelectorAll(".hero__toggle");

	if (hero) {
		for (let i = 0; i < heroButton.length; i++) {
			heroButton[i].addEventListener("click", function() {
				this.previousElementSibling.classList.toggle("show");
				this.nextElementSibling.classList.toggle("show");
				this.style.display = "none";
			});
		}
	}
});
