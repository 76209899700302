<template>
  <div>
    <div class="video-modal" v-if="open">
      <div class="video-modal__wrap wrap">
        <div class="video-modal__container">
          <video autoplay controls>
            <source :src="url" type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
        <button class="video-modal__close-btn" @click="open = false">
          {{ closeTxt }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "VideoLinkPlayer",
  props: {
    closeTxt: { type: [String], required: true },
  },
  data() {
    return {
      open: false,
      url: null,
    };
  },
  mounted() {
    let links = document.querySelectorAll('a[href$=".mov"], a[href$=".mp4"]');
    for (let i = 0; i < links.length; i++) {
      links[i].addEventListener("click", (event) => {
        event.preventDefault();
        this.url = links[i].href;
        this.open = true;
      });
    }
  },
};
</script>