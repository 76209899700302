<template>
    <div></div>
</template>

<script>
import { controller } from "../js/scroll";

export default {
  props: {
    src: { type: String, required: true }
  },
  data: () => ({
    playing: false
  }),
  async mounted() {
    const { default: lottie } = await import("lottie-web");
    this.lottie = lottie;
    this.player = lottie.loadAnimation({
      container: this.$el,
      path: this.src,
      autoplay: false
    });
    this.player.addEventListener("data_ready", this.dataReady);
  },
  methods: {
    dataReady() {
      this.scene = new ScrollMagic.Scene({
        offset: -300,
        triggerElement: this.$el
      })
        .on("enter", e => {
          setTimeout(() => {
            this.player.play();
          }, 100);
        })
        .addTo(controller);
    }
  }
};
</script>
